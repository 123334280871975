import { OfferLocation } from '@canalplus/sdk-core';
import { IContentD2G, IContentDownloaded } from '../../constants/DownloadToGo';
import { getPublicConfig } from '../config/config-helper';
import { injectScript } from '../scripts/scripts-helper';
import { getD2GUrl } from '../url/url-helper';
import { isWindows } from '../userAgent/userAgent-helper';

export const loadDownloadToGoScript = async (): Promise<void> => {
  const d2gSrc = getD2GUrl();
  await injectScript(d2gSrc, { isAsync: true });
};

/**
 * Return **content id** with content Downloaded
 *
 * @param availableContent this content is all download movie and seasons
 * @param contentID this id content for check is already
 *
 */
export const getContentIdWithContentDownloaded = (
  availableContent: IContentDownloaded[],
  contentID: string
): string | undefined => {
  if (availableContent.length === 0) return '';

  const content = availableContent.find((element) => element.metadata.contentID === contentID);

  if (!content) {
    return '';
  }

  return content?.id;
};

/**
 * Return all **Content with meta data** from season and movies
 *
 * @param contents all download movie and seasons
 *
 */
export const getContentsWithMetaData = (contents: IContentDownloaded[]): IContentD2G[] => {
  const contentsWithMetaData: IContentD2G[] = [];
  const contentsWithSeasonData: IContentDownloaded[][] = [];

  if (contents && contents.length > 0) {
    contents.forEach((content) => {
      if (content && content.metadata) {
        if (content.metadata?.contentType === 'season') {
          const serieId: string | undefined = content.metadata?.seasonInfo?.showId;
          const seasonId: string | undefined = content.metadata?.seasonInfo?.seasonId;

          if (!serieId || !seasonId) {
            return;
          }

          if (!contentsWithSeasonData[serieId]) {
            contentsWithSeasonData[serieId] = [];
          }

          if (!contentsWithSeasonData[serieId][seasonId]) {
            contentsWithSeasonData[serieId][seasonId] = [];
          }

          contentsWithSeasonData[serieId][seasonId].push({
            ...content.metadata,
            size: content.size,
            idItem: content.id,
          });
        } else {
          contentsWithMetaData.push({ ...content.metadata, size: content.size, idItem: content?.id });
        }
      }
    });
    contentsWithMetaData.push(...addSeasonInMeta(contentsWithSeasonData));
  }

  return contentsWithMetaData;
};

/**
 * Return all **size** with one season
 *
 * @param contents one seasons
 *
 */
const getSize = (contents: IContentD2G[]): number => {
  const size = contents.reduce(
    (previousValue, currentValue) => previousValue + (currentValue.size ? currentValue.size : 0),
    0
  );

  return size;
};

/**
 * Add **season in meta** and return metadatawithseason
 *
 * @param contentsWithSeasonData all download seasons
 *
 */
const addSeasonInMeta = (contentsWithSeasonData: IContentDownloaded[][]): IContentD2G[] => {
  const metadata: IContentD2G[] = [];

  Object.keys(contentsWithSeasonData).forEach((key) => {
    const serie = contentsWithSeasonData[key];
    const seasons: IContentD2G[] = [];
    let nbEp = 0;

    Object.keys(serie).forEach((id) => {
      const size = getSize(serie[id]);

      const season = {
        title: serie[id][0]?.title,
        contentID: serie[id][0]?.seasonInfo?.seasonId,
        label: serie[id][0]?.seasonInfo?.label,
        content: serie[id],
        size,
      };

      nbEp += serie[id].length;

      seasons.push(season);
    });

    const size = getSize(seasons);

    const seasonAdd = {
      ...seasons?.[0].content?.[0]?.seasonInfo,
      title: seasons?.[0]?.content?.[0]?.seasonInfo?.seasonTitle,
      subtitle: `${nbEp}  ${seasons[0]?.label}`,
      content: seasons,
      contentType: seasons?.[0]?.content?.[0]?.contentType,
      URLLogoChannel: seasons?.[0]?.content?.[0]?.URLLogoChannel,
      size,
    };

    metadata.push(seasonAdd);
  });

  return metadata;
};

export const isD2GSupported = (currentOfferLocation: OfferLocation): boolean =>
  (getPublicConfig().DOWNLOADTOGO.ALLOWED_OFFER_LOCATION.includes(currentOfferLocation) && isWindows()) || !isWindows();
