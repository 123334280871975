import { DiveTokensBrands } from '@dce-front/dive-tokens';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { DiveBreakpoint, DiveTheme } from '../types/Dive.types';

/**
 * Clamps given `value` between `min` and `max` inclusive
 *
 * @param value Value to clamp between `min` and `max`
 * @param min Minimum number of the range
 * @param max Maximum number of the range
 *
 * @example
 * clamp(150, 0, 100) // returns 100
 * clamp(-20, 0, 100) // returns 0
 */
export function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(min, value), max);
}

/**
 * Storybook stories component whose sole purpose is to add a label to the original component.
 * @param children - The original component to display
 * @param label - The label to display below the component
 * @returns The exact same component with a label below it
 * @example
 * <StorybookLabel label="This is a button">
 *   <Button />
 * </StorybookLabel>
 * <StorybookLabel label={<p>This is a button. <br />This is a new line.</p>}>
 *   <Button />
 * </StorybookLabel>
 */
export function StorybookLabel({
  label,
  children,
}: {
  label: ReactElement | string;
  children: ReactElement;
}): JSX.Element {
  return (
    <div className={classNames('flex flex-col items-center gap-8')}>
      {children}
      <span className="text-dt-theme-text-text-50 font-hind text-center text-12">{label}</span>
    </div>
  );
}

/**
 * Transforms an enum to an object with the same keys and values
 */
export function getStorybookThemes<T extends string>(enumToTransform: Record<string, T>): Record<string, T> {
  return Object.values(enumToTransform).reduce((themeEnumToObject: Record<string, T>, themeValue) => {
    themeEnumToObject[themeValue] = themeValue;
    return themeEnumToObject;
  }, {});
}

export function lowercaseInclude(text: string = '', subText: string = ''): boolean {
  return text.toLowerCase().includes(subText.toLowerCase());
}

/** Dive breakpoints number values */
const DIVE_BREAKPOINTS = Object.values(DiveBreakpoint).filter((v) => typeof v === 'number') as DiveBreakpoint[];

type BreakpointMatches = Record<DiveBreakpoint, boolean>;

/**
 * Returns which dive breakpoints are matched (smaller or equal than the window width).
 * @example
 * const matchedBreakpoints = useDiveBreakpoints();
 * // when Xs < window.innerWidth < Sm
 * console.log(matchedBreakpoints); // { [DiveBreakpoint.Xxs]: true, [DiveBreakpoint.Xs]: true, [DiveBreakpoint.Sm]: false, ... }
 */
export function useDiveBreakpoints(): BreakpointMatches {
  const [matches, setMatches] = useState<BreakpointMatches>(
    DIVE_BREAKPOINTS.reduce((acc, breakpoint) => ({ ...acc, [breakpoint]: false }), {} as BreakpointMatches)
  );
  useEffect(() => {
    const handleResize = throttle(() => {
      const currentWidth = window.innerWidth;
      const matchedBreakpoints = DIVE_BREAKPOINTS.reduce((acc, breakpoint) => {
        acc[breakpoint] = breakpoint <= currentWidth;
        return acc;
      }, {} as BreakpointMatches);
      setMatches(matchedBreakpoints);
    }, 250);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return matches;
}

export const mapDiveTokensBrandToLegacyDiveTheme = (diveTokensBrand: DiveTokensBrands): DiveTheme | null => {
  switch (diveTokensBrand) {
    case DiveTokensBrands.MyCanal:
      return DiveTheme.MyCanalDark;
    case DiveTokensBrands.Business:
      return DiveTheme.BusinessDark;
    case DiveTokensBrands.Tim:
      return DiveTheme.TelecomItalia;
    case DiveTokensBrands.Vod:
      return DiveTheme.VODDark;
    case DiveTokensBrands.Light:
      return DiveTheme.MyCanalLight;
    default:
      return null;
  }
};

export const mapLegacyDiveThemeToDiveTokensBrand = (diveTheme: DiveTheme): DiveTokensBrands | null => {
  switch (diveTheme) {
    case DiveTheme.MyCanalDark:
      return DiveTokensBrands.MyCanal;
    case DiveTheme.BusinessDark:
      return DiveTokensBrands.Business;
    case DiveTheme.TelecomItalia:
      return DiveTokensBrands.Tim;
    case DiveTheme.VODDark:
    case DiveTheme.VODaltDark:
      return DiveTokensBrands.Vod;
    case DiveTheme.MyCanalLight:
    case DiveTheme.BusinessLight:
    case DiveTheme.VODLight:
    case DiveTheme.VODaltLight:
      return DiveTokensBrands.Light;
    default:
      return null;
  }
};
