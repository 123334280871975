import classNames from 'classnames';
import IconTelerama from '../../../../../assets/svg/telerama.svg';
import styles from './Rating.css';

export type RatingTeleramaProps = {
  rate: number;
  reactKey: string;
};

/**
 * Rating telerama Component
 *
 * This component is in charge of displaying
 * the rating with telerama
 *
 * @param rate  The note to display
 */
function Rating({ rate, reactKey }: RatingTeleramaProps): JSX.Element | null {
  return rate > 0 ? (
    <ul className={classNames(styles.rating)}>
      {[...Array(rate).keys()].map((i) => {
        return (
          <li key={`${reactKey}--${i}`} className={classNames(styles.rating__telerama)}>
            <IconTelerama title="telerama" />
          </li>
        );
      })}
    </ul>
  ) : null;
}

export default Rating;
