import { getQsValueByKey } from '@canalplus/mycanal-commons';
import { ApiV2DetailV5 } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { QueryKeys } from '../../../constants/queryKeys';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import {
  featShortVideoPlayerSelector,
  getFeatureToggleTimeline,
} from '../../../selectors/application/application-selectors';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import DetailV5 from '../../../templates/DetailV5/components/DetailV5';
import { DetailProvider } from '../../../templates/DetailV5/data/provider';
import { QS_PROCESS_STATE } from '../../../templates/FunnelTvod/helpers/const';
import { LocationStateContext } from '../../../typings/routing';
import { DetailContextState } from '../data/types';

export type DetailV5ContainerProps = {
  routingContext: LocationStateContext;
  url: string;
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
} & FromProp;

function DetailV5Container({
  from,
  onFocusable,
  onClickParameters,
  routingContext,
  url,
}: DetailV5ContainerProps): JSX.Element | null {
  const callbackState = getQsValueByKey(url, QS_PROCESS_STATE);
  const isFeatShortVideoPlayerEnabled = useSelector(featShortVideoPlayerSelector);
  const isFeatTimelineEnabled = useSelector(getFeatureToggleTimeline);
  const [{ isLoading, error, data }] = useQueryTemplate<ApiV2DetailV5>(
    url,
    {
      from,
      onClickParameters,
      routingContext,
      template: FetchRequestTypes.DetailV5,
      enableAlternateLinksUpdate: true,
      options: { queryKeyPrefix: QueryKeys.DetailPage },
    },
    undefined,
    { isFeatShortVideoPlayerEnabled, isFeatTimelineEnabled }
  );

  const initData = useMemo(() => {
    return {
      ...data,
      callbackState,
      isFunnelTvodOpened: false,
    } as DetailContextState;
  }, [data, callbackState]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorTemplate error={error} />;
  }

  return data ? (
    <DetailProvider initData={initData}>
      <DetailV5 onFocusable={onFocusable} routingContext={routingContext} />
    </DetailProvider>
  ) : null;
}

export default DetailV5Container;
