import classNames from 'classnames';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import Linker from '../../../../../components/Linker/Linker';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import {
  pathnameSelector,
  routerOnClickSelector,
  routingContextSelector,
} from '../../../../../store/slices/routing-selectors';
import styles from './Tab.css';

export type TabProps = {
  active: boolean;
  displayName?: string;
  onClick: () => void;
  path?: string;
  tabsListRef: React.RefObject<HTMLUListElement>;
};

const SCROLL_MARGIN_LEFT_OFFSET = 100;

/**
 * Links to relevant Tab path while maintaining Tab parent's context data
 */
function Tab({ displayName, active, onClick, path, tabsListRef: { current: tabsListRef } }: TabProps): JSX.Element {
  const tabParentOnClick = useSelector(routerOnClickSelector);
  const pathname = useSelector(pathnameSelector);
  const context = useSelector(routingContextSelector);
  const isTvDevice = useSelector(displayTVModeSelector);

  const tabRef = useRef<HTMLLIElement>(null);
  const tabLeftOffset = tabRef?.current?.offsetLeft;

  // Horizontally scrolls into view an overflowed/hidden active tab
  if (active && tabsListRef && typeof tabLeftOffset === 'number') {
    setTimeout(
      () =>
        tabsListRef.scrollTo({
          left: tabLeftOffset - SCROLL_MARGIN_LEFT_OFFSET,
          behavior: 'smooth',
        }),
      900
    );
  }

  return (
    <li className={classNames(styles.tab, { [styles['tab--active']]: active })} ref={tabRef}>
      <Linker
        onClick={onClick}
        title={displayName}
        data={{ mainOnClick: { ...tabParentOnClick, path }, context }}
        data-navigateonfocus={!active}
        replace
      >
        {!isTvDevice && active && pathname === path ? <h1>{displayName}</h1> : displayName}
      </Linker>
    </li>
  );
}

export default Tab;
