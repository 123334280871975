import { universalService } from '../../../services/UniversalService';
import { FetchRequestTypes } from '../../../services/types';
import { FetchTemplateParameters } from '../../../templates/types';
import { getFormattedContentGridUrl } from './formater';

type FetchContentGridParameters = FetchTemplateParameters & {
  featIdentityV5?: boolean;
};

export async function fetchContentGrid({
  from,
  hodorSdkConfig,
  featIdentityV5 = false,
  onClickParameters,
  queryClient,
  URLPage,
}: FetchContentGridParameters): Promise<void> {
  const urlFormatted = getFormattedContentGridUrl({ url: URLPage, from });

  await queryClient.prefetchInfiniteQuery({
    queryKey: [urlFormatted], // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: () =>
      universalService({
        url: urlFormatted,
        fetchDetails: { from, hodorSdkConfig, onClickParameters, template: FetchRequestTypes.ContentGrid },
        formatterProps: { featIdentityV5 },
      }),
    initialPageParam: urlFormatted,
  });
}
