import { SecondaryActionState } from '@dce-front/hodor-types/modules/action_layout/definitions';
import classNames from 'classnames';
import IconRecording from '../../../../../../assets/svg/recording.svg';
import Badge from '../../../../../../components/Badge/Badge';
import { useSendTrackingOnCTAEvent } from '../../../../../../helpers/tracking/useSendTrackingOnCTAEvent';
import { isR7Available } from '../../../../../../helpers/window/window-helper';
import stylesDetail from '../../../DetailV5.css';
import { SecondaryActionType } from '../types';
import styles from './RecordingButton.css';

type RecordingButtonProps = {
  epgId: number;
  startDate: number;
  endDate: number;
  contentId?: string;
  isDisabled?: boolean;
  customClassBadgeV5?: string;
  statesLabel: SecondaryActionState[];
};

function RecordingButton({
  epgId,
  startDate,
  endDate,
  contentId,
  statesLabel,
  customClassBadgeV5,
  isDisabled = false,
}: RecordingButtonProps): JSX.Element {
  const { ariaLabel, label } = statesLabel[0] || {};
  const sendTrackingOnCTAEvent = useSendTrackingOnCTAEvent();

  const onClick = () => {
    if (isR7Available()) {
      window.R7('createRecord', {
        epgId,
        startDate,
        endDate,
        contentId,
      });
    }
    sendTrackingOnCTAEvent(SecondaryActionType.Recording);
  };

  return (
    <button
      className={classNames(styles.recordingButton, stylesDetail['detailV5--focus'])}
      type="button"
      data-testid="recording-button"
      aria-label={ariaLabel}
      disabled={isDisabled}
      onClick={onClick}
    >
      <Badge theme="button" disable={isDisabled} customClassBadgeV5={customClassBadgeV5}>
        <IconRecording data-testid="icon-recording" />
        {label && <span className={styles.recordingButton__text}>{label}</span>}
      </Badge>
    </button>
  );
}

export default RecordingButton;
