import { getQsValueByKey } from '@canalplus/mycanal-commons';
import { useStore } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import { ApiV2ActionLayout } from '@dce-front/hodor-types/api/v2/action_layout/definitions';
import { ApiV2DetailActionLayout } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QueryKeys } from '../../../../constants/queryKeys';
import { useAppHistory } from '../../../../helpers/hooks/reactRouter';
import { useQueryTemplate } from '../../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { LAYER_IMMERSIVE, LAYER_PAGE } from '../../../../helpers/oneNavigation/layers';
import { FromProp } from '../../../../server/modules/fetchWithQuery/types';
import { FetchRequestTypes } from '../../../../services/types';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { isImmersiveSelector } from '../../../../store/slices/immersive-selectors';
import { profileIdSelector } from '../../../../store/slices/user-selectors';
import { QS_PROCESS_STATE } from '../../../../templates/FunnelTvod/helpers/const';
import { useDetailContext, useDetailDispatch } from '../../data/provider';
import { setIsFunnelTvodOpened } from '../../data/store/actions';
import styles from '../DetailV5.css';
import CallbackModal from './CallbackModal/CallbackModal';
import PrimaryActions from './PrimaryActions/PrimaryActions';
import SecondaryActions from './SecondaryActions/SecondaryActions';
import { useOnSuccess } from './useOnSuccess';

export type ActionLayoutProps = {
  actionLayout?: ApiV2DetailActionLayout;
  scrollToTab: (tabIndexToScroll: number | null) => void;
  toggleActionLayoutLoading: (value: boolean) => void;
  onFocusable?: () => void;
} & FromProp;

function ActionLayout({
  actionLayout,
  from,
  scrollToTab,
  toggleActionLayoutLoading,
  onFocusable,
}: ActionLayoutProps): JSX.Element {
  const profileId = useSelector(profileIdSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const store = useStore();
  const history = useAppHistory();
  const isImmersive = useSelector(isImmersiveSelector);
  const detailDispatch = useDetailDispatch();
  const [isCallbackModalOpen, setOpenCallbackModal] = useState(false);
  const { callbackMessage } = useDetailContext();

  const isQSCallbackState = getQsValueByKey(history.location.search, QS_PROCESS_STATE) !== '';

  const {
    actionLayoutPerso,
    primaryActions: primaryActionsFallback,
    secondaryActions: secondaryActionsFallback,
  } = actionLayout || {};
  const [{ data, isLoading, isFetching, isError }] = useQueryTemplate<ApiV2ActionLayout>(
    actionLayoutPerso?.URLPage,
    {
      from,
      template: FetchRequestTypes.ActionLayout,
      onClickParameters: actionLayoutPerso?.parameters,
      options: { profileId, queryKeyPrefix: QueryKeys.DetailActionLayout },
    },
    {
      enabled: !!actionLayoutPerso?.URLPage,
    }
  );

  useOnSuccess(data);

  const handleCloseCallbackModal = useCallback(() => {
    /**
     * Remove callbackState from path
     */
    history.replace({
      ...history.location,
      search: '',
    });
    setOpenCallbackModal(false);
  }, [history]);

  useEffect(() => {
    toggleActionLayoutLoading(isLoading);
  }, [isLoading, toggleActionLayoutLoading]);

  useEffect(() => {
    // Sometimes bugged, may execute before layer changed in store
    // So I forced it to execute after the layer changed in Immersive.tsx
    if (onFocusable && (data || !actionLayoutPerso?.URLPage || isError)) {
      onFocusable();
    }
  }, [data, isError, onFocusable, actionLayoutPerso?.URLPage]);

  useEffect(() => {
    if (callbackMessage && isQSCallbackState) {
      setOpenCallbackModal(true);
    }
  }, [callbackMessage, isQSCallbackState]);

  useEffect(() => {
    // prevent the trailer preview starting when the callbackModal is opened
    detailDispatch(setIsFunnelTvodOpened(isCallbackModalOpen));
  }, [detailDispatch, isCallbackModalOpen]);

  useEffect(() => {
    if (onFocusable && data && !isCallbackModalOpen) {
      if (isImmersive) {
        store.setActiveLayer(LAYER_IMMERSIVE);
      } else {
        store.setActiveLayer(LAYER_PAGE);
      }
      onFocusable();
    }
  }, [store, onFocusable, data, isCallbackModalOpen, isImmersive]);

  const primaryActions = data?.actionLayout?.primaryActions || primaryActionsFallback;
  const secondaryActions = data?.actionLayout?.secondaryActions || secondaryActionsFallback;

  // Don't active binder until we've finished loading since unmount can occurs. This mess up TV focus
  // we always need to focus primary action when it's available
  return (
    <div
      className={classNames(styles.detailV5__actionLayoutInfos, {
        [styles['detailV5__actionLayoutInfos--fadeIn']]: !isLoading || isTvDevice,
      })}
    >
      <PrimaryActions
        from={Template.ActionLayout}
        isLoading={isLoading}
        primaryActions={primaryActions}
        scrollToTab={scrollToTab}
      />
      {secondaryActions && (
        <SecondaryActions
          secondaryActions={secondaryActions}
          isFetchingPushOpinion={isFetching}
          hasPushOpinion={data?.pushOpinion}
        />
      )}
      {isCallbackModalOpen && (
        <CallbackModal onCloseModal={handleCloseCallbackModal} callbackMessage={callbackMessage} />
      )}
    </div>
  );
}

export default ActionLayout;
