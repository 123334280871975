import { Binder, enter, memory, spatial, useLayer } from '@canalplus/one-navigation';
import { ApiV2DetailTab } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import classNames from 'classnames/bind';
import { RefObject, memo, useCallback, useEffect, useMemo, useState } from 'react';
import Spinner from '../../../../components/Spinner/Spinner';
import { isPointerVisible } from '../../../../helpers/binder/pointer';
import { useAppLocation } from '../../../../helpers/hooks/reactRouter';
import { scroll, snapToTop } from '../../../../helpers/oneNavigation/scroll';
import { hideTabHorizontalNavigation } from '../../../../helpers/oneNavigation/tabs';
import { FromProp } from '../../../../server/modules/fetchWithQuery/types';
import { getDefaultTabIndex } from '../../data/helper';
import TabPanel from './TabPanel/TabPanel';
import styles from './Tabs.css';
import TabsList from './TabsList/TabsList';
import { usePrefetchDetailTabs, useShowTabsContent } from './hooks';

const cx = classNames.bind(styles);

export type TabsProps = {
  isTvDevice: boolean;
  isImmersive?: boolean;
  isActionLayoutLoading?: boolean;
  setTabIndexToScroll: React.Dispatch<React.SetStateAction<number | null>>;
  tabIndexToScroll: number | null;
  tabs: ApiV2DetailTab[];
  tabsRef: RefObject<HTMLElement>;
} & FromProp;

function Tabs({
  from,
  isTvDevice,
  isImmersive,
  isActionLayoutLoading = false,
  setTabIndexToScroll,
  tabs,
  tabIndexToScroll,
  tabsRef,
}: TabsProps): JSX.Element {
  const { pathname } = useAppLocation();

  // Computes the default tab index to focus
  const { defaultTabIndex, isTabTargetedByUrl } = useMemo(() => getDefaultTabIndex(tabs, pathname), [tabs, pathname]);

  const layer = useLayer();
  const showTabContent = useShowTabsContent(isImmersive);
  const defaultTab = tabs[defaultTabIndex];
  // If we are on the root url of the detailPage (without specific tab url),
  // the main meta of the page must not be overridden by that of the default tab.
  const disableMetaUpdate = !isTabTargetedByUrl;

  const [currentTab, setCurrentTab] = useState<ApiV2DetailTab | undefined>(defaultTab);
  const [isTabVisible, setIsTabVisible] = useState<boolean>(true);

  // Generates a new one-navigation middleware each time the defaultIndex or pathname changes
  const middleware = useMemo(() => {
    return [
      hideTabHorizontalNavigation(setIsTabVisible),
      scroll([snapToTop]),
      enter({ forceFocusIndex: defaultTabIndex, shouldForceFocusOnce: true }),
      memory(),
      spatial(),
    ];

    // Here we want to reset the middleware on each page change, that's why we use pathname in
    // dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTabIndex, pathname, setIsTabVisible]);

  useEffect(() => {
    // defaultTab changes each time the user navigates through the immersive
    if (defaultTab) {
      setIsTabVisible(true);
      setCurrentTab(defaultTab);
    }
  }, [defaultTab]);

  useEffect(() => {
    // allows the user to navigate from the top of the detail page to the tabulation using the anchor given in the onClick of primaryActions
    if (tabIndexToScroll !== null && tabIndexToScroll >= 0) {
      setIsTabVisible(true);
      setCurrentTab(tabs[tabIndexToScroll]);

      const delayScroll = setTimeout(() => {
        if (tabsRef.current) {
          if (isTvDevice && !isPointerVisible()) {
            layer.getBinderById('tabs')?.focusByIndex(0);
          } else if (!isImmersive) {
            /* .scrollTo() prevents bug where the scroll would stop midway */
            const top = tabsRef.current.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top, behavior: 'smooth' });
          } else {
            /* Can't use window.scrollTo() while in an Immersive, or else it will scroll on the Home page */
            tabsRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }

        setTabIndexToScroll(null);
      }, 100);

      return () => clearTimeout(delayScroll);
    }

    return;
  }, [tabsRef, setTabIndexToScroll, tabIndexToScroll, tabs, isImmersive, layer, isTvDevice]);

  const switchTab = useCallback(
    (tab: ApiV2DetailTab, index = 0) => {
      setIsTabVisible(true);
      setCurrentTab(tab);
      if (!isTvDevice || isPointerVisible()) {
        setTabIndexToScroll(index);
      }
    },
    [setTabIndexToScroll, isTvDevice]
  );

  usePrefetchDetailTabs({ from, tabs, path: pathname });

  return (
    <>
      <div id="tabs-anchor" className={cx('tabsAnchor')} />
      <nav
        className={cx('tabsListWrapper', 'tabsWrapper', {
          'tabsListWrapper-immersive': isImmersive,
        })}
      >
        <Binder
          binderId="tabs"
          middleware={middleware}
          className={cx('tabsWrapper__content', { 'tabsWrapper__content--fadeIn': !isActionLayoutLoading })}
        >
          <TabsList
            tabs={tabs}
            displayNameActivated={isTabVisible ? currentTab?.displayName : ''}
            switchTab={switchTab}
          />
        </Binder>
      </nav>
      {!!currentTab?.URLPage && (
        <main className={cx('tabsPanelWrapper', 'tabsWrapper', { 'tabsPanelWrapper__hidden': !isTabVisible })}>
          {showTabContent && (
            <div className={cx('tabsWrapper__content', { 'tabsWrapper__content--fadeIn': !isActionLayoutLoading })}>
              <TabPanel
                from={from}
                disableMetaUpdate={disableMetaUpdate}
                displayTemplate={currentTab.displayTemplate}
                URLPage={currentTab.URLPage}
                onClickParameters={currentTab?.parameters}
              />
            </div>
          )}
        </main>
      )}
      {!isTabVisible && <Spinner />}
    </>
  );
}

export default memo(Tabs);
