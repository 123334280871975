import { HashKey } from '@canalplus/mycanal-util-react';
import PressReview from '../../../../../templates/DetailV5/components/Informations/PressReview/PressReview';
import styles from './Reviews.css';

export type Rating = {
  type?: string;
  value: number;
};

export type Review = {
  name?: string;
  review?: string;
  rating: Rating;
} & HashKey;

export type ReviewsProps = {
  reviews: Review[];
};

/**
 * Reviews
 *
 * renders a list of reviews
 * @param reviews list of reviews to render
 */
function Reviews({ reviews }: ReviewsProps): JSX.Element | null {
  return reviews.length ? (
    <ul className={styles.reviews}>
      {reviews.map((data) => {
        const { name, rating, hash } = data;

        if (!rating) return;

        return (
          <li className={styles.reviews__item} key={hash}>
            {name && <span className={styles.reviews__name}>{name}</span>}
            <PressReview type={rating?.type} rate={rating.value} />
          </li>
        );
      })}
    </ul>
  ) : null;
}

export default Reviews;
