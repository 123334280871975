import { ApiV2DetailActionLayout, ApiV2DetailV5 } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import { PrimaryActionOnClick } from '@dce-front/hodor-types/modules/action_layout/primary/definitions';
import { useDetailContext } from '../provider';

// A collection of selectors to avoid detail context destructuring in every component

/** @public */
export const useActionLayout = (): ApiV2DetailActionLayout | undefined => useDetailContext()?.actionLayout;

/** @public */
export const useDetail = (): ApiV2DetailV5['detail'] => useDetailContext()?.detail;

export const useNextURLActionLayout = (): string | undefined => useDetailContext()?.nextURLActionLayout;

export const usePrimaryActionContentId = (): PrimaryActionOnClick['contentID'] =>
  useDetailContext()?.primaryActionContentId;
