import classNames from 'classnames';
import IconStarFull from '../../../../../assets/svg/starFull.svg';
import IconStarGradient from './IconStarGradient';
import styles from './Rating.css';

export type RatingProps = {
  rate: number;
  max: number;
  primaryColor: string;
  secondaryColor: string;
  reactKey: string;
};

/**
 * Rating stars Component
 *
 * This component is in charge of displaying
 * the rating with stars
 *
 * @param rate                 The note to display
 * @param max                  The maximum score that can be displayed (can be the same value as rate for the ratio)
 * @param primaryColor         color of a full star
 * @param secondaryColor       color of an empty star
 */
function Rating({ rate, max, primaryColor, secondaryColor, reactKey }: RatingProps): JSX.Element {
  const rating: JSX.Element[] = [];
  const rateFloor = Math.floor(rate);
  const rateDecimalRatio = (rate - rateFloor) * 100;
  let starToRender: JSX.Element;

  for (let i = 1; i <= max; i += 1) {
    const key = `${reactKey}--${i}`;

    if (i <= rateFloor) {
      starToRender = <IconStarFull color={primaryColor} data-testid="rating_star_full" />;
    } else if (i < rate + 1) {
      starToRender = (
        <IconStarGradient
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          threshold={rateDecimalRatio}
          reactKey={key}
          data-testid="rating_star_half"
        />
      );
    } else {
      starToRender = <IconStarFull color={secondaryColor} data-testid="rating_star_empty" />;
    }

    rating.push(
      <li key={key} className={classNames(styles.rating__stars)}>
        {starToRender}
      </li>
    );
  }

  return <ul className={classNames(styles.rating)}>{rating}</ul>;
}

export default Rating;
