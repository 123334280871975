import { ModalV2, ModalV2Type } from '@canalplus/mycanal-sharedcomponent';
import { KEY_BACK, useKeyCatcher } from '@canalplus/one-navigation';
import { useSelector } from 'react-redux';
import { LAYER_IMMERSIVE_MOREINFOS } from '../../../../../helpers/oneNavigation/layers';
import { FromProp } from '../../../../../server/modules/fetchWithQuery/types';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import LoadableMoreInfos from '../../../../../templates/DetailV5/components/MoreInfos';

export type MoreInfosModalProps = {
  setOpenMoreInfosModal: (isMoreInfosModalOpen: boolean) => void;
  URLPage: string;
} & FromProp;

function MoreInfosModal({ from, setOpenMoreInfosModal, URLPage }: MoreInfosModalProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const handleClose = () => setOpenMoreInfosModal(false);
  useKeyCatcher(KEY_BACK, handleClose, LAYER_IMMERSIVE_MOREINFOS);

  return (
    <ModalV2
      type={ModalV2Type.MoreInfos}
      isTvDevice={isTvDevice}
      onBack={handleClose}
      onClose={handleClose}
      id="modal_moreinfos"
    >
      <LoadableMoreInfos from={from} url={URLPage} />
    </ModalV2>
  );
}

export default MoreInfosModal;
