import { ButtonIcon, DetailPageDownloadSvg } from '@canalplus/dive';
import { customProtocolCheck } from '@canalplus/mycanal-commons';
import { ModalV2, ModalV2Size, ModalV2Type } from '@canalplus/mycanal-sharedcomponent';
import { SecondaryActionState } from '@dce-front/hodor-types/modules/action_layout/definitions';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Badge from '../../../../../../components/Badge/Badge';
import { DownloadStateId } from '../../../../../../constants/DownloadToGo';
import { getPublicConfig } from '../../../../../../helpers/config/config-helper';
import { useAppDispatch } from '../../../../../../helpers/hooks/useAppDispatch';
import { injectScript, scriptExist } from '../../../../../../helpers/scripts/scripts-helper';
import { isMacOs } from '../../../../../../helpers/userAgent/userAgent-helper';
import I18n from '../../../../../../lang';
import {
  downloadManagerUrlSelector,
  renderSourceSelector,
} from '../../../../../../selectors/application/application-selectors';
import { openDownloadToGo } from '../../../../../../store/reducers/actions/DownloadToGo-actions';
import {
  microEligibilitySelector,
  passTokenSelector,
  subscriberIdSelector,
} from '../../../../../../store/slices/user-selectors';
import { useDetailContext } from '../../../../../../templates/DetailV5/data/provider';
import stylesSecondaryActions from '../SecondaryActions.css';
import styles from './D2G.css';
import { createUrl, isD2GClientLegacyAvailable } from './D2GHelper';
import D2GModal from './D2GModal/D2GModal';
import D2GUnavailableModal from './D2GUnavailableModal/D2GUnavailableModal';

export type RenderD2GProps = {
  isTvDevice: boolean;
  contentID: string;
  showLabel?: boolean;
  statesLabel?: SecondaryActionState[];
};

export const TIMEOUT_PROTOCOL_DETECT = 4000;

function RenderD2G({ isTvDevice, contentID, statesLabel, showLabel = true }: RenderD2GProps): JSX.Element {
  const [isModalOpen, setOpenModal] = useState(false);
  const dispatch = useAppDispatch();

  const config = getPublicConfig();
  const { t } = I18n.useTranslation();

  const microEligibility: string = useSelector(microEligibilitySelector);
  const passToken: string = useSelector(passTokenSelector);
  const subscriberId: string = useSelector(subscriberIdSelector)?.toString() || '';
  const D2GManagerURL: string = useSelector(downloadManagerUrlSelector) || '';
  const isClient = useSelector(renderSourceSelector) === 'client';

  // This code need to be removed when Silverlight Module is no longer working
  const UVIIBundleUrl = config.DOWNLOADTOGO.BUNDLE;
  const UVIIBundleHost = config.DOWNLOADTOGO.HOST;

  const downloadToGoAppUrl =
    isClient && isMacOs() ? `${D2GManagerURL}myCanal.pkg` : `${D2GManagerURL}myCANAL-setup.exe`;

  /**
   * D2G custom mycanal client is available only on windows and macOs (except new version of MacOs)
   * For others systems (android, ios), we propose to download app in the store
   */
  const isD2GClientLegacy = isClient && isD2GClientLegacyAvailable();

  const pathContent = useDetailContext()?.currentPage?.path;
  const protocolHandlerUrl = config.DOWNLOADTOGO.CUSTOM_PROTOCOL_URL.replace(
    '{pathContent}',
    pathContent || ''
  ).replace('{contentId}', contentID);

  const downloadContentUrl = useMemo(
    () => createUrl(contentID, passToken, subscriberId, microEligibility),
    [contentID, passToken, subscriberId, microEligibility]
  );

  // @TODO Manage label from state of download. Need to copy logic OnePlayer_D2G from detail v4 (apps/mycanal/src/components/ActionBar/ActionBar.tsx)
  // Before this change, use always the label NotDownloaded
  const stateLabel = statesLabel?.find((elem) => elem.id === DownloadStateId.NotDownloaded);
  const downloadLabel = stateLabel?.label || t('DownloadToGo.downloads');

  // This code need to be removed when Silverlight Module is no longer working
  const appDesktopFound = useCallback(() => {
    setOpenModal(false);

    // trigger download on external app
    return window.UVII?.launchDeeplink(downloadContentUrl);
  }, [downloadContentUrl]);
  // This code need to be removed when Silverlight Module is no longer working
  const appDesktopNotFound = () => {
    setOpenModal(true);
  };

  const triggerD2G = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isClient) {
      if (event) {
        event.stopPropagation();
      }
      dispatch(openDownloadToGo());

      // This code need to be removed when Silverlight Module is no longer working
      if (isD2GClientLegacy) {
        // Inject UVII external script if not already injected
        if (!scriptExist(UVIIBundleUrl)) {
          await injectScript(UVIIBundleUrl, { isAsync: true });
        }

        if (!window.UVII) {
          return;
        }

        window.UVII.host = UVIIBundleHost;
        //  Listen and wait for D2G app to be installed then:
        //  - trigger deeplink if installed
        //  - display D2GModal if D2G App not installed, and ping until it is.
        window.UVII?.waitForAppInstalled(appDesktopFound, appDesktopNotFound);
      }

      if (!isD2GClientLegacy) {
        customProtocolCheck(
          protocolHandlerUrl,
          // Failed callback
          () => {
            setOpenModal(true);
          },
          // Success callback
          () => {
            setOpenModal(false);
          },
          TIMEOUT_PROTOCOL_DETECT,
          () => {
            setOpenModal(true);
          }
        );
      }
    }
  };

  return (
    <>
      {isTvDevice ? (
        <button
          className={styles.downloadBtn}
          onClick={triggerD2G}
          tabIndex={0}
          data-tool-id="click-download"
          type="button"
          aria-label={downloadLabel}
          id="downloadBtnId"
        >
          <Badge
            theme="button"
            customClassBadgeV5={stylesSecondaryActions.secondaryActions__D2GBtn}
            hasHoverStyle={false}
          >
            <DetailPageDownloadSvg title={downloadLabel} />
            {showLabel && <span className={styles.downloadBtn__text}>{downloadLabel}</span>}
          </Badge>
        </button>
      ) : (
        <ButtonIcon
          id="downloadBtnId"
          aria-label={downloadLabel}
          icon={<DetailPageDownloadSvg title={downloadLabel} />}
          onClick={triggerD2G}
          {...(showLabel && { label: downloadLabel })}
        />
      )}
      {isModalOpen && (
        <ModalV2
          title={!isD2GClientLegacy ? t('DownloadToGoMacOsModal.title') : t('DownloadToGoModal.title')}
          size={!isD2GClientLegacy ? ModalV2Size.D2G : undefined}
          type={!isD2GClientLegacy ? ModalV2Type.D2G : undefined}
          onClose={() => setOpenModal(false)}
          portalId={contentID}
          idFrom={document.getElementById('downloadBtnId')}
          closeAriaLabel={t('DownloadToGoMacOsModal.closeBtn')}
        >
          {!isD2GClientLegacy ? (
            <D2GUnavailableModal />
          ) : (
            <D2GModal downloadToGoAppUrl={downloadToGoAppUrl} downloadContentUrl={downloadContentUrl} />
          )}
        </ModalV2>
      )}
    </>
  );
}

export default RenderD2G;
