import Color from '../../../../../constants/colors';
import { REVIEW_SENS_CRITIQUE, REVIEW_TELECABLE, REVIEW_TELERAMA } from '../../../../../constants/reviews';
import RatingStars from '../../../../../templates/DetailV5/components/Informations/Rating/RatingStars';
import RatingTelerama from '../../../../../templates/DetailV5/components/Informations/Rating/RatingTelerama';
import styles from './PressReview.css';

export type PressReviewProps = {
  type?: string;
  rate: number;
};

enum ReactKey {
  Telerama = 'REVIEW_TELERAMA',
  Telecable = 'REVIEW_TELECABLE',
  Default = 'default',
}

/**
 * PressReview Component
 *
 * This component is in charge of displaying
 * reviews notation depending on the type of press
 *
 * @param type type of pressRating
 * @param rate rating number
 */
function PressReview({ type, rate }: PressReviewProps): JSX.Element | null {
  switch (type) {
    case REVIEW_TELERAMA: {
      if (rate > 4) return null;
      return <RatingTelerama rate={rate} reactKey={type || ReactKey.Telerama} />;
    }

    case REVIEW_TELECABLE:
      return (
        <RatingStars
          rate={rate}
          max={4}
          primaryColor={Color.Telerama}
          secondaryColor={Color.GreyDark}
          reactKey={type || ReactKey.Telecable}
        />
      );

    case REVIEW_SENS_CRITIQUE:
      return <span className={styles.pressReview__score}>{rate}</span>;

    default:
      return (
        <RatingStars
          rate={rate}
          max={5}
          primaryColor={Color.Allocine}
          secondaryColor={Color.GreyDark}
          reactKey={type || ReactKey.Default}
        />
      );
  }
}

export default PressReview;
