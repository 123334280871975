import { Binder } from '@canalplus/one-navigation';
import { SecondaryActionState } from '@dce-front/hodor-types/modules/action_layout/definitions';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import IconMoodDislike from '../../assets/svg/moodDislike.svg';
import IconMoodLike from '../../assets/svg/moodLike.svg';
import IconMoodNeutral from '../../assets/svg/moodNeutral.svg';
import { Moods } from '../../constants/mood';
import { LAYER_IMMERSIVE_MOOD } from '../../helpers/oneNavigation/layers';
import { MIDDLEWARE_MOOD } from '../../helpers/oneNavigation/middleware';
import { useSendTrackingOnCTAEvent } from '../../helpers/tracking/useSendTrackingOnCTAEvent';
import I18n from '../../lang';
import type { userListService } from '../../services/PlaylistService';
import styles from './Mood.css';

export type MoodProps = {
  userRating?: Moods;
  contentIDs: string[];
  userListService: typeof userListService;
  closeModal: (mood: Moods) => void;
  setPersoUpdated: (value: boolean) => void;
  statesLabel?: SecondaryActionState[];
};

/**
 * Mood Component
 *
 * This component is in charge of displaying Mood template
 *
 * @param userRating        nonopinion, neutral, like, dislike
 * @param contentIDs        array of contentID
 * @param updateUserRating  action to update the userRating on store
 * @param userListService   action to send a post to the ws
 * @param mood              mood from the store
 */
function Mood({
  closeModal,
  contentIDs,
  userRating = Moods.NoOpinion,
  userListService,
  setPersoUpdated,
  statesLabel,
}: MoodProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const [activeMood, setActiveMood] = useState(userRating);
  const sendTrackingOnCTAEvent = useSendTrackingOnCTAEvent();

  const statesLabelMap = useMemo(() => {
    return statesLabel
      ? statesLabel.reduce<Record<Moods, SecondaryActionState>>(
          (acc, state) => {
            acc[state.id] = state;
            return acc;
          },
          {} as Record<string, never>
        )
      : undefined;
  }, [statesLabel]);

  const { [Moods.Dislike]: dislike, [Moods.Like]: like, [Moods.Neutral]: neutral } = statesLabelMap || {};

  const handleClick = (moods: Moods) => {
    if (userRating !== moods) {
      sendTrackingOnCTAEvent(moods);
      setPersoUpdated(true);
      setActiveMood(moods);

      // Add new mood
      userListService('add', contentIDs, moods);
    }

    setTimeout(() => closeModal(moods), 500);
  };

  return (
    <div className={styles.mood}>
      <Binder layer={LAYER_IMMERSIVE_MOOD} middleware={MIDDLEWARE_MOOD}>
        <ul className={styles.mood__items}>
          <li
            className={classNames(styles.mood__item, styles['mood__item--dislike'], {
              [styles['mood__item--active']]: activeMood === Moods.Dislike,
            })}
            id={Moods.Dislike}
          >
            <button
              className={classNames(styles.mood__button, 'globalMoodButton')}
              id={`${Moods.Dislike}_onclick`}
              onClick={() => handleClick(Moods.Dislike)}
              data-testid="dislike-button"
              type="button"
              aria-label={dislike?.ariaLabel}
            >
              <IconMoodDislike className={styles.mood__icon} />
              <span className={styles.mood__item__title}>{dislike?.label || t('Mood.dislike')}</span>
            </button>
          </li>

          <li
            className={classNames(styles.mood__item, styles['mood__item--neutral'], {
              [styles['mood__item--active']]: activeMood === Moods.Neutral,
            })}
            id={Moods.Neutral}
          >
            <button
              className={classNames(styles.mood__button, 'globalMoodButton')}
              data-testid="neutral-button"
              id={`${Moods.Neutral}_onclick`}
              onClick={() => handleClick(Moods.Neutral)}
              type="button"
              aria-label={neutral?.ariaLabel}
            >
              <IconMoodNeutral className={styles.mood__icon} />
              <span className={styles.mood__item__title}>{neutral?.label || t('Mood.neutral')}</span>
            </button>
          </li>
          <li
            className={classNames(styles.mood__item, styles['mood__item--like'], {
              [styles['mood__item--active']]: activeMood === Moods.Like,
            })}
            id={Moods.Like}
          >
            <button
              className={classNames(styles.mood__button, 'globalMoodButton')}
              id={`${Moods.Like}_onclick`}
              data-testid="like-button"
              onClick={() => handleClick(Moods.Like)}
              type="button"
              aria-label={like?.ariaLabel}
            >
              <IconMoodLike className={styles.mood__icon} />
              <span className={styles.mood__item__title}>{like?.label || t('Mood.like')}</span>
            </button>
          </li>
        </ul>
      </Binder>
    </div>
  );
}

export default Mood;
