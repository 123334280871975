import { DIMENSIONS, Ratio } from '@canalplus/mycanal-commons';
import { ApiV2Cover } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import ThumborMediaImage from '../../../../components/ThumborMediaImage/ThumborMediaImage';
import { Video } from '../../../../components/Video';
import { useAppDispatch } from '../../../../helpers/hooks/useAppDispatch';
import { getFeatureToggleTrailerPreview } from '../../../../selectors/application/application-selectors';
import { FromProp } from '../../../../server/modules/fetchWithQuery/types';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { setIsTrailerTemporarilyUnmuted } from '../../../../store/slices/user';
import { isTrailerAutoplaySelector } from '../../../../store/slices/user-selectors';
import { useIsTrailerMuted } from '../../hooks/useIsTrailerMuted';
import styles from './Cover.css';

const cx = classNames.bind(styles);

export type CoverProps = {
  cover: ApiV2Cover;
  isVisible?: boolean;
  title?: string;
} & FromProp;

function Cover({ from, cover, isVisible = true, title }: CoverProps): JSX.Element {
  const { compactImage, regularImage, trailer } = cover;
  const isTrailerAutoplay = useSelector(isTrailerAutoplaySelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const hasTrailer = useSelector(getFeatureToggleTrailerPreview) && Boolean(trailer?.URLMedias) && isTrailerAutoplay;
  const compactImgRatio = compactImage?.imageRatio || Ratio.Ratio34;
  const regularImgRatio = regularImage?.imageRatio || Ratio.Ratio169;
  const dispatch = useAppDispatch();
  const isTrailerMuted = useIsTrailerMuted();

  const coverImage = (
    <ThumborMediaImage
      url={regularImage?.URLImage}
      urlMobile={compactImage?.URLImage}
      alt={title}
      dimensions={{
        default: DIMENSIONS.COVER_DETAIL_V5[regularImgRatio],
        mobile: DIMENSIONS.COVER_DETAIL_V5[compactImgRatio],
      }}
    />
  );

  return (
    <div
      className={cx('cover', `cover--compact-ratio-${compactImgRatio}`, `cover--regular-ratio-${regularImgRatio}`)}
      data-testid="detailv5-cover"
    >
      {hasTrailer ? (
        <Video
          areBlackBarsHidden
          cover={coverImage}
          from={from}
          hasFullFrameIndicator={isTvDevice}
          isMuted={isTrailerMuted}
          isVisible={isVisible}
          urlMedias={cover.trailer?.URLMedias}
          onSoundChange={(value: boolean) => {
            dispatch(setIsTrailerTemporarilyUnmuted(!value));
          }}
        />
      ) : (
        coverImage
      )}
    </div>
  );
}

export default Cover;
