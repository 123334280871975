import { CATALINA_VERSION } from '../../../../../../constants/userAgent';
import { extractOffers } from '../../../../../../helpers/eligibility/eligibility-helper';
import { getEncodedPassId } from '../../../../../../helpers/user/user-helper';
import { isMacOs, isNewerVersion, isWindows } from '../../../../../../helpers/userAgent/userAgent-helper';

export const createUrl = (
  contentID: string,
  passToken: string,
  subscriberId: string,
  microEligibility: string
): string => {
  const passId = getEncodedPassId();
  const passIdQuery = passId ? `&passid=${passId}` : '';

  const idName = 'hapiId';
  const encodedContentId = encodeURIComponent(contentID);
  const encodedPassToken = encodeURIComponent(passToken);
  const encodedMicroEligibility = encodeURIComponent(extractOffers(microEligibility).join());
  const encodedSubscriberId = encodeURIComponent(subscriberId);

  return `mycanal://${idName}=${encodedContentId}${passIdQuery}&passtoken=${encodedPassToken}&microeligibility=${encodedMicroEligibility}&subscriberId=${encodedSubscriberId}`;
};

/**
 * D2G custom mycanal client is available only on windows and macOs (except new version of MacOs)
 * For others systems (android, ios), we propose to download app in the store
 */
export const isD2GClientLegacyAvailable = (): boolean => {
  const isMacOsNewVersion = isMacOs() && isNewerVersion(CATALINA_VERSION);
  const isWindowsOrMacOs = isWindows() || isMacOs();
  return isWindowsOrMacOs && !isMacOsNewVersion;
};
