/**
 * Offers
 *
 * In charge to return
 * the current offers of a user
 * @param {string} macro
 * @param {string} microEligibility
 * @returns {array}
 */
const getMicros = (macro: string, microEligibility: string): string[] => {
  const reg = new RegExp(`${macro}:\\[(.*?)\\]`);
  const res = reg.exec(microEligibility);
  if (!res) {
    return [];
  }

  return res[1].split(',').filter((micro) => !!micro);
};

export const extractOffers = (
  microEligibility: string,
  macros = ['ALD_CP', 'ALD_CS', 'CPL_INF', 'ALD_TVGRAT']
): string[] =>
  macros.reduce((offers, macro) => offers.concat(getMicros(macro, microEligibility)), [] as string[]).sort();

/**
 *
 * @param {string} macro - all the macro eligibilities
 * @param {string} microEligibility - all the micro eligibilities
 * @param {string} micro - the micro to find
 *
 * @returns {boolean} - true if the micro is actually in the macros
 */
export const isMicroInMacro = (macro: string, microEligibility: string, micro: string): boolean =>
  getMicros(macro, microEligibility).indexOf(micro) !== -1;

/**
 * Test if micro is present in microEligibilities, whatever the macro
 * @param  {string} microEligibility - User micro eligibilities
 * @param  {string} micro - Micro to find
 * @returns {boolean} True if micro is present, false otherwise
 */
export const isMicroInMicroEligibilities = (microEligibility: string, micro: string): boolean =>
  microEligibility.indexOf(micro) !== -1;
