import { cva } from 'class-variance-authority';

export const tooltipViewCVA = cva(
  [
    'relative inline-flex flex-col items-start',
    'h-fit p-8 pt-9 pb-7',
    'bg-dt-theme-surface-tooltip-tooltip rounded-sm',
    'font-hind text-12 text-dt-theme-text-tooltip-tooltip leading-4 max-w-[21rem]',
  ],
  {
    variants: {
      width: {
        fluid: ['w-fit'],
        fixed: ['w-fit min-w-[7.25rem]'],
      },
    },
  }
);
