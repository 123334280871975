export const PRIMARY_ACTION_TYPE = {
  Deeplink: 'deeplink',
  Play: 'play',
  Transactional: 'transactional',
  Unavailable: 'unavailable',
  Synchronize: 'synchronize',
} as const;

export const PRIMARY_ACTION_SUBTYPE = {
  FromDiffusion: 'fromDiffusion',
  highlighted: 'highlighted',
  Internal: 'internal',
  LiveTV: 'liveTV',
  LiveOnGoing: 'liveOnGoing',
  LiveOnGoingWithDescription: 'liveOnGoingWithDescription',
  Rental: 'rental',
  StartOver: 'startover',
  StreamOnGoing: 'streamOnGoing',
  StartOverFromDiffusion: 'startOverFromDiffusion',
  WithDescription: 'withDescription',
  NotShaded: 'notShaded',
} as const;
