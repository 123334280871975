import classNames from 'classnames';
import styles from './Badge.css';

export type BadgeProps = {
  active?: boolean;
  children: React.ReactNode;
  customClass?: string;
  customClassBadgeV5?: string;
  disable?: boolean;
  hasHoverStyle?: boolean;
  size?: 'medium' | 'small' | 'large';
  theme?: 'button' | 'twitter' | 'facebook';
  variant?: 'rounded' | 'download';
};

/** Displays a badge with an icon */
function Badge({
  active,
  children,
  customClass = '',
  customClassBadgeV5 = '',
  disable,
  hasHoverStyle = true,
  size,
  theme,
  variant,
}: BadgeProps): JSX.Element {
  return (
    <div
      className={classNames(styles.badge, {
        [styles['badge--disabled']]: disable,
        [styles['badge--hasHover']]: hasHoverStyle,
        [styles[`badge--active`]]: active,
        [styles[`badge--${size}`]]: size,
        [styles[`badge--${theme}`]]: theme,
        [styles[`badge--${variant}`]]: variant,
        [customClass]: customClass,
        [customClassBadgeV5]: customClassBadgeV5,
      })}
    >
      {children}
    </div>
  );
}

export default Badge;
