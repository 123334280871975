import { Button } from '@canalplus/dive';
import { ModalV2 } from '@canalplus/mycanal-sharedcomponent';
import { Binder, KEY_BACK, useActiveLayer, useKeyCatcher } from '@canalplus/one-navigation';
import { ApiV2CallbackMessage } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../helpers/hooks/useAppDispatch';
import { LAYER_CALLBACK_MODAL } from '../../../../../helpers/oneNavigation/layers';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import { updatePurchaseInfo } from '../../../../../store/slices/purchaseCode';
import styles from './CallbackModal.css';

export type CallbackModalProps = {
  onCloseModal: () => void;
  callbackMessage?: ApiV2CallbackMessage;
};

function CallbackModal({ onCloseModal, callbackMessage }: CallbackModalProps): JSX.Element {
  const isTvDevice = useSelector(displayTVModeSelector);
  const dispatch = useAppDispatch();
  const callbackModalButtonWidth = isTvDevice ? 'fixed' : 'full';

  const handleClose = async () => {
    dispatch(updatePurchaseInfo(undefined));
    onCloseModal();
  };

  useActiveLayer(LAYER_CALLBACK_MODAL);
  useKeyCatcher(KEY_BACK, handleClose, LAYER_CALLBACK_MODAL);

  return (
    <ModalV2
      hideCloseButton
      className={styles.callbackModal}
      isTvDevice={isTvDevice}
      size="small"
      onClose={handleClose}
    >
      <Binder layer={LAYER_CALLBACK_MODAL} className={classNames(styles.callbackModal__content)}>
        <div className={classNames(styles.callbackModal__title)}>{callbackMessage?.title}</div>
        <span className={styles.callbackModal__subtitle}>{callbackMessage?.subtitle}</span>
        <Button
          width={callbackModalButtonWidth}
          type="submit"
          variant="secondary"
          onClick={handleClose}
          className={styles.callbackModal__button}
        >
          {callbackMessage?.button.label}
        </Button>
      </Binder>
    </ModalV2>
  );
}

export default CallbackModal;
