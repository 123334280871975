import { ApiV2ActionLayout } from '@dce-front/hodor-types/api/v2/action_layout/definitions';
import { useEffect } from 'react';
import { useDetailDispatch } from '../../data/provider';
import { setDetailPerso, setNextURLActionLayout, setPrimaryActionContentId } from '../../data/store/actions';

/** Dispatch some data to the `DetailProvider` */
export const useOnSuccess = (data?: ApiV2ActionLayout): void => {
  const detailDispatch = useDetailDispatch();

  useEffect(() => {
    if (data?.actionLayout) {
      const { primaryActions, actionLayoutPerso } = data.actionLayout;

      if (primaryActions) {
        const onClickFirstPA = primaryActions?.[0]?.onClick;
        if (onClickFirstPA) {
          detailDispatch(setPrimaryActionContentId(onClickFirstPA.contentID));
        }
      }

      // Whenever the data contains a new `URLActionLayout` (without context), we store it in the provider for
      // future use later with the refetching on leaving the player.
      if (actionLayoutPerso?.URLPage) {
        detailDispatch(setNextURLActionLayout(actionLayoutPerso?.URLPage));
      }
    }

    // If new `detail` data available, we reload/override the context detail information. (No need to override
    // the provider's context with `actionLayout` data, since we only need it here in this component and we have
    // access directly to it )
    if (data?.detail) {
      detailDispatch(setDetailPerso(data.detail));
    }
  }, [data, detailDispatch]);
};
