import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { universalService } from '../../../services/UniversalService';
import { FetchRequestTypes } from '../../../services/types';
import { hasPageCover } from '../../../store/slices/page';
import { LandingV5State, getFormattedLandingUrl } from '../../../templates/LandingV5/data/formatter';
import { FetchTemplateParameters } from '../../../templates/types';

type GetLandingTemplateDataParameters = FetchTemplateParameters & {
  dispatch?: Redux.Dispatch;
  featIdentityV5?: boolean;
  isTvDevice: boolean;
};

export async function fetchLanding({
  dispatch,
  featIdentityV5 = false,
  from,
  hodorSdkConfig,
  isTvDevice,
  onClickParameters,
  queryClient,
  URLPage,
}: GetLandingTemplateDataParameters): Promise<void> {
  const urlFormatted = getFormattedLandingUrl(URLPage, isTvDevice);

  const { cover } = await queryClient.fetchQuery({
    queryKey: [urlFormatted], // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: () =>
      universalService<LandingV5State & { tracking?: Tracking }>({
        url: urlFormatted,
        fetchDetails: { from, hodorSdkConfig, onClickParameters, template: FetchRequestTypes.LandingV5 },
        formatterProps: { featIdentityV5, dispatch },
      }),
  });

  // Store cover value in store (used to determine Header's transparency)
  if (cover) {
    dispatch?.(hasPageCover(true));
  }
}
