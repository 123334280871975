import { cva } from 'class-variance-authority';

export const progressBarLinearBackgroundCVA = cva('relative w-full rounded-4 overflow-hidden text-white', {
  variants: {
    device: {
      'tv': 'h-8 min-h-[0.5rem]',
      'web': 'h-4 min-h-[0.25rem]',
    },
    shouldForceDarkTheme: {
      true: 'bg-dt-theme-background-progressbar-progressbar-forced-dark',
      false: undefined,
    },
  },
  compoundVariants: [
    {
      device: 'tv',
      shouldForceDarkTheme: false,
      className: 'bg-dt-theme-tv-surface-progressbar-progressbar-background',
    },
    {
      device: 'web',
      shouldForceDarkTheme: false,
      className: 'bg-dt-theme-background-progressbar-progressbar',
    },
  ],
});

export const progressBarLinearForegroundCVA = cva('absolute top-0 h-[inherit] min-h-[inherit]', {
  variants: {
    device: {
      'tv': undefined,
      'web': undefined,
    },
    variant: {
      live: undefined,
      ongoing: undefined,
    },
    shouldForceDarkTheme: {
      true: undefined,
      false: undefined,
    },
  },
  compoundVariants: [
    {
      variant: 'live',
      device: 'web',
      className: 'bg-dt-theme-surface-progressbar-progressbar-live',
    },
    {
      variant: 'live',
      device: 'tv',
      className: 'bg-dt-theme-tv-surface-progressbar-progressbar-live',
    },
    {
      variant: 'ongoing',
      device: 'web',
      shouldForceDarkTheme: false,
      className: 'bg-dt-theme-surface-progressbar-progressbar-on-going',
    },
    {
      variant: 'ongoing',
      device: 'tv',
      shouldForceDarkTheme: false,
      className: 'bg-dt-theme-tv-surface-progressbar-progressbar-on-going',
    },
    {
      variant: 'ongoing',
      shouldForceDarkTheme: true,
      className: 'bg-white-100',
    },
  ],
});

export const progressBarLabelCVA = cva('font-hind w-30p whitespace-nowrap', {
  variants: {
    shouldForceDarkTheme: {
      true: 'text-white-100',
      false: 'text-dt-theme-text-text-50',
    },
    variant: {
      live: 'relative before:content-[""] before:rounded-full before:absolute-y-center',
      ongoing: undefined,
    },
    device: {
      tv: 'text-24',
      web: 'text-12',
    },
  },
  compoundVariants: [
    {
      variant: 'ongoing',
      device: 'web',
      className: 'pl-8',
    },
    {
      variant: 'ongoing',
      device: 'tv',
      className: 'pl-12',
    },
    {
      variant: 'live',
      device: 'web',
      className: 'pl-20 before:w-8 before:h-8 before:left-8 before:bg-dt-theme-surface-progressbar-progressbar-live',
    },
    {
      variant: 'live',
      device: 'tv',
      className:
        'pl-[28px] before:w-[10px] before:h-[10px] before:left-12 before:bg-dt-theme-tv-surface-progressbar-progressbar-live',
    },
  ],
});
