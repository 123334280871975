import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import Linker from '../../../../../components/Linker/Linker';
import { LinkerData } from '../../../../../components/Linker/types';
import { routerOnClickSelector, routingContextSelector } from '../../../../../store/slices/routing-selectors';
import styles from './MoreInfosButton.css';

const cx = classNames.bind(styles);

export type MoreInfosButtonProps = {
  path?: string;
  label: string;
  handleOpenMoreInfo: (isMoreInfosModalOpen: boolean) => void;
};

function MoreInfosButton({ path, label, handleOpenMoreInfo }: MoreInfosButtonProps): JSX.Element {
  const tabParentOnClick = useSelector(routerOnClickSelector);
  const context = useSelector(routingContextSelector);

  const newData: LinkerData = {
    mainOnClick: { ...tabParentOnClick, displayTemplate: tabParentOnClick?.displayTemplate, path },
    context,
  };

  const handleOnClick = () => {
    handleOpenMoreInfo(true);
  };

  return (
    <div className={cx('moreInfosButton__wrapper')}>
      <Linker
        title={label}
        onClick={handleOnClick}
        id="moreInfo-button"
        className={cx('moreInfosButton', 'moreInfosButton--focus')}
        data={newData}
        replace
      >
        <span className={cx('moreInfosButton__text')}>{label}</span>
      </Linker>
    </div>
  );
}

export default MoreInfosButton;
