import { isClientSide } from '@canalplus/mycanal-commons';

export const scrollElementToTop = (idElement: string): void => {
  if (!isClientSide()) {
    return;
  }
  const selectedElement = document.getElementById(idElement);

  if (!selectedElement) {
    return;
  }

  selectedElement.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};
