import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import { FromProp } from '../../../../../server/modules/fetchWithQuery/types';
import { FetchDetails } from '../../../../../services/types';
import LoadableContentGrid from '../../../../../templates/ContentGrid';
import LoadableContentGridHighlights from '../../../../../templates/ContentGridHighlights';
import LoadableMoreInfos from '../../../../../templates/DetailV5/components/MoreInfos';
import LoadableLandingV5Container from '../../../../../templates/LandingV5';
import LoadableSportVitrine from '../../../../../templates/SportVitrine';
import LoadableEpisodesListContainer from '../../EpisodesList/components/EpisodesListContainer/LoadableEpisodesListContainer';
import styles from './TabPanel.css';

const cx = classNames.bind(styles);

export type TabPanelProps = {
  URLPage: string;
  disableMetaUpdate?: boolean;
  displayTemplate?: string;
  onClickParameters?: FetchDetails['onClickParameters'];
} & FromProp;

function TabPanel({
  disableMetaUpdate = false,
  displayTemplate = '',
  onClickParameters,
  URLPage,
  from,
}: TabPanelProps): JSX.Element {
  const getTabPanelTemplate = () => {
    const sharedProps = { disableMetaUpdate, from, url: URLPage, isFromDetail: from === Template.DetailPage } as const;

    switch (displayTemplate) {
      case Template.ContentGrid:
        return <LoadableContentGrid onClickParameters={onClickParameters} {...sharedProps} />;
      case Template.EpisodesList:
        return <LoadableEpisodesListContainer onClickParameters={onClickParameters} {...sharedProps} />;
      case Template.Highlights:
        return <LoadableContentGridHighlights onClickParameters={onClickParameters} {...sharedProps} />;
      case Template.Landing:
        return <LoadableLandingV5Container onClickParameters={onClickParameters} {...sharedProps} />;
      case Template.MoreInfos:
        return <LoadableMoreInfos {...sharedProps} />;
      case Template.SportVitrine:
        return <LoadableSportVitrine onClickParameters={onClickParameters} {...sharedProps} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={cx('tabPanel', {
        // Don't add padding for HSContentRow and ContentGrid templates (inside or outside of LandingV5 template)
        'tabPanel--no-padding': [
          Template.Landing.toString(),
          Template.ContentGrid.toString(),
          Template.Highlights.toString(),
        ].includes(displayTemplate),
      })}
    >
      {getTabPanelTemplate()}
    </div>
  );
}

export default TabPanel;
