import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { ImageType } from '@canalplus/mycanal-sharedcomponent';
import { useSelector } from 'react-redux';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';

export type URLLogoProps = {
  URLLogoChannel?: string;
  URLLogoBrand?: string;
};

/**
 * URLLogo Component
 *
 * @param URLLogoChannel                 Logo for Channel
 * @param URLLogoBrand                   Logo for Brand
 */
function URLLogo({ URLLogoChannel, URLLogoBrand }: URLLogoProps): JSX.Element {
  const dimensionsLogo = DIMENSIONS.LOGO_V5;
  const isTvDevice = useSelector(displayTVModeSelector);

  return (
    <>
      {URLLogoChannel && (
        <ImageType URLImage={{ default: URLLogoChannel }} dimensions={dimensionsLogo} isTvDevice={isTvDevice} />
      )}
      {URLLogoBrand && (
        <ImageType URLImage={{ default: URLLogoBrand }} dimensions={dimensionsLogo} isTvDevice={isTvDevice} />
      )}
    </>
  );
}

export default URLLogo;
