import { useSelector } from 'react-redux';
import { useIsTrailerMuted as useIsSettingsTrailerMuted } from '../../../components/HeaderMyCanal/UserMenuMyCanalExtended/UserMenuSections/components/SettingsTV/AnimationsAutoplay/hooks/useIsTrailerMuted';
import { isTrailerTemporarilyUnmutedSelector } from '../../../store/slices/user-selectors';

export const useIsTrailerMuted = (): boolean => {
  const isTrailerTemporarilyUnmuted = useSelector(isTrailerTemporarilyUnmutedSelector);
  const { isTrailerMuted } = useIsSettingsTrailerMuted(); // settings TV only

  return isTrailerTemporarilyUnmuted ? false : isTrailerMuted;
};
