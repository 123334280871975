import { MaterialUpdateSvg } from '@canalplus/dive';
import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import IconDownArrowDPv5 from '../../../../../assets/svg/downArrowDPv5.svg';
import IconExternalLink from '../../../../../assets/svg/externalLink.svg';
import IconMarket from '../../../../../assets/svg/market.svg';
import IconPlay from '../../../../../assets/svg/play.svg';
import IconStartOver from '../../../../../assets/svg/startover3.svg';
import Button, { ButtonProps } from '../../../../../components/Button/Button';
import type { ButtonLinkerProps } from '../../../../../components/ButtonLinker/ButtonLinker';
import ButtonLinker from '../../../../../components/ButtonLinker/ButtonLinker';
import { PRIMARY_ACTION_SUBTYPE, PRIMARY_ACTION_TYPE } from '../../../../../constants/primaryAction';
import { getLocationStateContext } from '../../../../../helpers/contents/contents-helper';
import { CustomPrimaryAction, CustomPrimaryActionOnClick } from '../../../../../templates/DetailV5/data/types';
import { getPrimaryActionTypes } from '../helpers';
import stylesDetail from './../../DetailV5.css';
import styles from './PrimaryActionButton.css';

export type PrimaryActionButtonProps = {
  bigLabelMinimumLength?: number;
  handler?: () => void;
  isButtonLinker?: boolean;
  isLoading: boolean;
  isPrimary?: boolean;
  onClick?: CustomPrimaryActionOnClick;
  primaryAction: CustomPrimaryAction;
  shouldHandleBigLabel?: boolean;
};

const PRIMARY_ACTION_BUTTON_ICONS = {
  [PRIMARY_ACTION_TYPE.Play]: IconPlay,
  [PRIMARY_ACTION_TYPE.Transactional]: IconMarket,
  [PRIMARY_ACTION_TYPE.Deeplink]: IconExternalLink,
  [PRIMARY_ACTION_TYPE.Synchronize]: MaterialUpdateSvg,
  // Subtype-specific icons
  [PRIMARY_ACTION_SUBTYPE.StartOver]: IconStartOver,
  [PRIMARY_ACTION_SUBTYPE.StartOverFromDiffusion]: IconStartOver,
  [PRIMARY_ACTION_SUBTYPE.Internal]: IconDownArrowDPv5,
} as const;

function PrimaryActionButtonIcon({ subtype, type }: { subtype: string; type: string }): JSX.Element | null {
  const hasSubtypeSpecificIcon = [
    String(PRIMARY_ACTION_SUBTYPE.StartOver),
    String(PRIMARY_ACTION_SUBTYPE.StartOverFromDiffusion),
    String(PRIMARY_ACTION_SUBTYPE.Internal),
  ].includes(subtype);
  const iconKey = hasSubtypeSpecificIcon ? subtype : type;
  const Icon = PRIMARY_ACTION_BUTTON_ICONS[iconKey];

  return Icon ? (
    <Icon role="img">
      <title>{`${iconKey} SVG icon`}</title>
    </Icon>
  ) : null;
}

/**
 * Generates a button specific to a Primary Action type/subtype
 */
function PrimaryActionButton({
  bigLabelMinimumLength,
  handler,
  isButtonLinker = false,
  isLoading,
  isPrimary = false,
  primaryAction,
  shouldHandleBigLabel = true,
}: PrimaryActionButtonProps): JSX.Element {
  const { subtype = '', label = '', disabled: isDisabled = false, type = '', onClick, description } = primaryAction;
  const { displayTemplate, URLPage } = onClick || {};

  const { isPlay, isDeepLink, isUnavailable, isTransactional, isInternalAnchor } = getPrimaryActionTypes({
    type,
    subtype,
  });
  const isTransactionalExternal = isTransactional && displayTemplate === Template.ExternalSite;
  const isTransactionalStub = isTransactional && displayTemplate === Template.Stub;
  const isTransactionalFunnel = isTransactional && onClick?.displayTemplate === Template.ContextualOfferPage;

  const shouldSpreadLocationStateContext =
    isButtonLinker && !isDisabled
      ? (isPlay && !!URLPage) ||
        ((isTransactionalStub || isTransactionalFunnel) && !!URLPage) ||
        isTransactionalExternal
      : false;

  const sharedProps: ButtonLinkerProps | ButtonProps = {
    bigLabelMinimumLength,
    shouldHandleBigLabel,
    isDisabled: isDisabled || isUnavailable,
    isLoading,
    isV5Style: true,
    text: label,
    ariaLabel: label,
    id: `primary_action_${type || subtype}_Button`,
    icon: <PrimaryActionButtonIcon subtype={subtype} type={type} />,
    className: classNames(styles.primaryActionButton, {
      [stylesDetail['detailV5--focus']]: !isDisabled && !isUnavailable,
      [styles['primaryActionButton-primary']]: isPrimary && !isUnavailable && !isInternalAnchor,
      [styles['primaryActionButton-disabled']]: isDisabled,
      [styles['primaryActionButton-unavailable']]: isUnavailable,
      [styles['primaryActionButton-deeplink']]: isDeepLink,
    }),
    'data-tv-focusmode': 'attribute',
  } as const;

  return (
    <>
      {isButtonLinker ? (
        <ButtonLinker
          {...sharedProps}
          linkerData={{
            mainOnClick: onClick,
            ...(shouldSpreadLocationStateContext && { context: getLocationStateContext(onClick?.displayTemplate) }),
          }}
        />
      ) : (
        <Button {...sharedProps} handler={handler} />
      )}
      {description && (
        <span className={styles.primaryActionButton__description} role="contentinfo">
          {description}
        </span>
      )}
    </>
  );
}

export default PrimaryActionButton;
