import { HashObjectReturn, mapStaticKey } from '@canalplus/mycanal-util-react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Linker from '../../../../../components/Linker/Linker';
import Unicode from '../../../../../constants/unicode';
import { displayTVModeSelector } from '../../../../../store/slices/displayMode-selectors';
import styles from './Metadatas.css';
import {
  Metadata,
  Personality,
  ProductionNationality,
  isPersonalityMetadata,
  isProductionNationalityMetadata,
} from './Metadatas.type';

type MetadataItemProps = { title?: string; isLastElement: boolean };

/**
 * Display an individual metadata item
 */
function MetadataSubItem({ title, isLastElement }: MetadataItemProps): JSX.Element {
  return (
    <>
      {title}
      {!isLastElement && <span>,{Unicode.Space}</span>}
    </>
  );
}

type MetadatasItemsProps = { metadata: Metadata };

/**
 * Displays a list of DetailV5 metadatas items
 */
function MetadatasItem({ metadata }: MetadatasItemsProps): JSX.Element | null {
  const isTvDevice = useSelector(displayTVModeSelector);

  const retrieveMetadataItems = (): Personality[] | ProductionNationality[] => {
    if (isPersonalityMetadata(metadata)) {
      return metadata.personalitiesList;
    } else if (isProductionNationalityMetadata(metadata)) {
      return metadata.productionNationalitiesList;
    } else {
      return [];
    }
  };

  const metadataPrefix = metadata.prefix;
  const metadatasItems: HashObjectReturn<Personality | ProductionNationality>[] = mapStaticKey(
    retrieveMetadataItems(),
    'title'
  );

  const metadatasItemsLength = metadatasItems.length;

  return metadatasItemsLength ? (
    <div
      className={classNames(
        styles.metadatas__list,
        isPersonalityMetadata(metadata) && styles.metadatas__personality,
        isProductionNationalityMetadata(metadata) && styles.metadatas__prodNationality
      )}
    >
      {metadataPrefix && (
        <span className={styles.metadatas__list__prefix}>{`${metadataPrefix}${Unicode.NoBreakSpace}`}</span>
      )}

      {metadatasItems.map((metadataItem: HashObjectReturn<Personality | ProductionNationality>, index: number) => {
        const { hash, title } = metadataItem;
        const isLastElement = index === metadatasItemsLength - 1;

        return (
          <span key={hash} className={styles.metadatas__list__item} data-testid="metadata-item-id">
            {'onClick' in metadataItem && !isTvDevice ? (
              <Linker data={{ mainOnClick: metadataItem.onClick }} title={title} replace={false} rel="nofollow">
                <MetadataSubItem title={title} isLastElement={isLastElement} />
              </Linker>
            ) : (
              <MetadataSubItem isLastElement={isLastElement} title={title} />
            )}
          </span>
        );
      })}
    </div>
  ) : null;
}

export default MetadatasItem;
