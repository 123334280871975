type IconStarGradientProps = {
  primaryColor: string;
  secondaryColor: string;
  threshold: number;
  reactKey: string;
};

function IconStarGradient({ primaryColor, secondaryColor, threshold, reactKey }: IconStarGradientProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" tabIndex={0} role="img">
      <defs>
        <linearGradient id={reactKey}>
          <stop offset="0%" stopColor={primaryColor} />
          <stop offset={`${threshold}%`} stopColor={primaryColor} />
          <stop offset={`${threshold}%`} stopColor={secondaryColor} />
          <stop offset="100%" stopColor={secondaryColor} />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${reactKey})`}
        d="M10.51 0L7.26 6.581 0 7.638 5.257 12.76 4.015 20 10.51 16.579 17.005 20 15.765 12.76 21.023 7.638 13.755 6.581z"
      />
    </svg>
  );
}

export default IconStarGradient;
