import { v4 as uuidv4 } from 'uuid';
import Button from '../../../../../../../components/Button/Button';
import step1 from '../../../../../../../components/DownloadToGo/images/download-to-go-step1.png';
import step2 from '../../../../../../../components/DownloadToGo/images/download-to-go-step2.png';
import step3 from '../../../../../../../components/DownloadToGo/images/download-to-go-step3.png';
import { ButtonShapes } from '../../../../../../../constants/button';
import { TemplateTypes } from '../../../../../../../constants/templateTypes';
import I18n from '../../../../../../../lang';
import styles from './D2GModal.css';

export type D2GModalProps = {
  downloadToGoAppUrl: string;
  downloadContentUrl: string;
};

/**
 * DownloadToGoModal component
 *
 * display download link for D2G app
 * @param {string}   downloadToGoAppUrl    url to download the app for D2G
 * @param {string}   downloadContentUrl    url to download the content
 * @returns {node}
 */
function D2GModal({ downloadToGoAppUrl, downloadContentUrl }: D2GModalProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const uniqueID = uuidv4();

  return (
    <div className={styles.downloadToGoModal}>
      <ol className={styles.downloadToGoModal__items}>
        <li className={styles.downloadToGoModal__item} key={`${uniqueID}--step1`}>
          <div className={styles.downloadToGoModal__step}>
            <div className={styles.downloadToGoModal__stepNumber}>
              <img src={step1} alt="" />
            </div>
          </div>
          <div className={styles.downloadToGoModal__stepTitle}>
            <h2 className={styles.downloadToGoModal__stepTitleLabel}>
              <Button
                className={styles.downloadToGoModal__button}
                color={TemplateTypes.SECONDARY}
                shape={ButtonShapes.OVAL}
                text={t('DownloadToGoModal.step1Title')}
                link={downloadToGoAppUrl}
              />
            </h2>
          </div>
          <p className={styles.downloadToGoModal__label}>{t('DownloadToGoModal.step1Text')}</p>
        </li>
        <li className={styles.downloadToGoModal__item} key={`${uniqueID}--step2`}>
          <div className={styles.downloadToGoModal__step}>
            <div className={styles.downloadToGoModal__stepNumber}>
              <img src={step2} alt="" />
            </div>
          </div>
          <div className={styles.downloadToGoModal__stepTitle}>
            <h2 className={styles.downloadToGoModal__stepTitleLabel}>{t('DownloadToGoModal.step2Title')}</h2>
          </div>
          <p className={styles.downloadToGoModal__label}>{t('DownloadToGoModal.step2Text')}</p>
        </li>
        <li className={styles.downloadToGoModal__item} key={`${uniqueID}--step3`}>
          <div className={styles.downloadToGoModal__step}>
            <div className={styles.downloadToGoModal__stepNumber}>
              <img src={step3} alt="" />
            </div>
          </div>
          <div className={styles.downloadToGoModal__stepTitle}>
            <h2 className={styles.downloadToGoModal__stepTitleLabel}>{t('DownloadToGoModal.step3Title')}</h2>
          </div>
          <p className={styles.downloadToGoModal__label}>{t('DownloadToGoModal.step3Text')}</p>
        </li>
      </ol>
      <p className={styles.downloadToGoModal__option}>
        {t('DownloadToGoModal.optionText')} <a href={downloadContentUrl}>{t('DownloadToGoModal.optionBtn')}</a>
      </p>
    </div>
  );
}

export default D2GModal;
