import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset as offsetFloatingUI,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDiveContext } from '../../context';
import { TooltipPosition, TooltipProps, TooltipTransition, TooltipViewWidth } from './Tooltip.types';
import { tooltipViewCVA } from './TooltipView.cva';
import { ARROW_HEIGHT, ARROW_OFFSET, ARROW_WIDTH, TRANSITIONS, TRANSITION_DURATIONS } from './constants';

/**
 * A Tooltip is a small text that appears when the user hovers over an element.
 * It is used to explain an interface element or feature to the user.
 *
 * @example
 * <Tooltip content="description" position='top'>
 *    <p>text</p>
 * </Tooltip>
 */
export const Tooltip = React.forwardRef<ReactElement, TooltipProps>(function Tooltip(
  {
    content,
    id,
    position = TooltipPosition.TopCenter,
    className,
    children,
    isVisible = false,
    showOnHover = true,
    width = TooltipViewWidth.Fluid,
    transition = TooltipTransition.MoveFade,
    'data-testid': dataTestId = 'dive-tooltip',
  }: TooltipProps,
  propRef
): JSX.Element {
  const { isTv } = useDiveContext();
  const idGenerated = useMemo(() => id || uuidv4(), [id]);
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen || isVisible,
    onOpenChange: setIsOpen,
    placement: position,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offsetFloatingUI({ mainAxis: ARROW_OFFSET.px + ARROW_HEIGHT.px, alignmentAxis: -ARROW_HEIGHT.px }),
      flip(),
      arrow({ element: arrowRef }),
    ],
  });
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  const { isMounted, styles: stylesTransition } = useTransitionStyles(context, {
    duration: TRANSITION_DURATIONS,
    initial: ({ side }) => {
      return TRANSITIONS[transition][side];
    },
  });

  // Event listeners to change the open state
  const hover = useHover(context, { enabled: showOnHover });
  const focus = useFocus(context, { enabled: showOnHover });
  const dismiss = useDismiss(context, { enabled: showOnHover });
  const intractions = [focus, hover, dismiss];

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions(intractions);

  if (isTv || !content) {
    return children;
  }

  const visibleTooltip = (isOpen || isVisible) && isMounted;

  return (
    <>
      {React.cloneElement(
        children,
        getReferenceProps({
          ref,
          ...children.props,
          'aria-describedby': idGenerated,
        })
      )}

      <FloatingPortal>
        {visibleTooltip && (
          <div
            id={idGenerated}
            role="tooltip"
            data-testid={dataTestId}
            // by default use a z-index 2000 (arbitrary value) to be sure the tooltip is over all the elements
            className={classNames('z-[2000]', className)}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div style={stylesTransition}>
              <span className={tooltipViewCVA({ width })}>{content}</span>
              <FloatingArrow
                className="fill-dt-theme-surface-tooltip-tooltip"
                width={ARROW_WIDTH.px}
                height={ARROW_HEIGHT.px}
                ref={arrowRef}
                context={context}
              />
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
});
