import { ApiV2DetailV5 } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import type { DetailContextState } from '../types';
import { ActionType, DetailV5Action } from './actions';

const detailV5Reducer = (state: ApiV2DetailV5, action: DetailV5Action): DetailContextState => {
  switch (action.type) {
    case ActionType.SET_ACTION_LAYOUT:
      return {
        ...state,
        actionLayout: { ...state.actionLayout, ...action.payload?.actionLayout },
      };

    case ActionType.SET_DETAIL_PERSO:
      return {
        ...state,
        detail: { ...state.detail, ...action.payload?.detail },
      };

    case ActionType.SET_IS_FUNNEL_TVOD_OPENED:
      return {
        ...state,
        isFunnelTvodOpened: action.payload.isOpened,
      };

    case ActionType.SET_URL_ACTION_LAYOUT:
      return {
        ...state,
        actionLayout: {
          primaryActions: [],
          secondaryActions: [],
          ...state.actionLayout,
          actionLayoutPerso: {
            displayTemplate: '',
            ...state.actionLayout?.actionLayoutPerso,
            URLPage: action.payload.URLActionLayout,
          },
        },
      };

    case ActionType.SET_DETAIL_DATA:
    case ActionType.SET_PRIMARY_ACTION_CONTENT_ID:
    case ActionType.SET_NEXT_URL_ACTION_LAYOUT:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default detailV5Reducer;
